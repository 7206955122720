<template>
  <div class="card">
	
    <validation-observer ref="VFormAskep">
      <b-form @submit.prevent="doSubmit">
				<div class="card-header bg-white">
					<h6 class="card-title font-weight-semibold">Tambah Asuhan Keperawatan</h6>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label class="" for="">Tanggal</label>
								<div class="input-group">
									<datepicker input-class="form-control transparent"
											placeholder="Tanggal" class="my-datepicker"
											calendar-class="my-datepicker_calendar"
											v-model="rowData.aranas_date">
									</datepicker>
									<div class="input-group-append calendar-group">
											<span class="input-group-text" id="basic-addon2"><i
															class="icon-calendar"></i></span>
									</div>
								</div>
								<VValidate :name="`Tanggal`" v-model="rowData.aranas_date"
										:rules="{required: 1}" />
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label class="" for="">Jam</label>
								<div class="input-group">
									<div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
									<vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="rowData.aranas_jam">
									</vue-timepicker>
								</div>
								<VValidate :name="`Jam`" v-model="rowData.aranas_jam"
										:rules="{required: 1}" />
							</div>
						</div>
					</div>

					<div class="card border shadow-0">
							<div class="card-header bg-info">
									<h5 class="card-title font-weight-semibold">Standar Diagnosis Keperawatan Indonesia (SDKI)</h5>
							</div>
							<div class="card-header pt-0">
									<ul class="nav nav-tabs nav-tabs-bottom mb-0">

											<li class="nav-item" v-for="(v,k) in diagnosa" :key="k">
													<a href="javascript:;" @click="changeTab(k+1)" data-toggle="tab" :data-target="'#diagTab'+(k+1)"
															:class="isActiveTab == (k+1) ? 'nav-link active' : 'nav-link'">{{v.text||"Diagnosa baru"}}
															<i @click="removeDiagnosa(v.aranasd_id,k)" class="icon-cross ml-2"></i>
													</a>
											</li>

											<li class="nav-item"><a @click="addNewDiagnosa" href="javascript:;" class="nav-link">
															<i class="icon-plus2 mr-2"></i> Tambah Diagnosa
													</a></li>
									</ul>
							</div>

							<div class="card-body">
									<div class="tab-content">
											<div v-for="(v,k) in diagnosa" :key="k+'a'"
													:class="isActiveTab == (k+1) ? 'tab-pane fade show active':'tab-pane fade'"
													:id="'#diagTab'+(k+1)">
													<div class="row g-3">
															<div class="col-md-8">
																	<label for="diagnosa">Pilih Diagnosa</label>
																	<v-select placeholder="Pilih Diagnosa" class="diagnosa-section select-paging" v-model="diagnosa[k].aranasd_diagnosa_id"
																			@input="selectPenyebab($event,k)" @search="filterSDKI($event,k)"
																			:options="getMasterSDKI(mSDKI,diagnosa,k)" label="text"
																			:clearable="true" :reduce="v=>v.value">
																			<template slot="selected-option">
																					<span>{{ v.text || "-" }}</span>
																			</template>

																			<template #list-footer>
																				<li class="list-paging" v-if="ceilData(v.totalRows/10)">
																					<div class="d-flex align-items-center justify-content-between">
																						<b-button @click="changePageSDKI(v.currentPage,k,'min','diagnosa')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
																						<small>Halaman {{v.currentPage}} dari {{ceilData(v.totalRows/10)}}</small>
																						<b-button @click="changePageSDKI(v.currentPage,k,'plus','diagnosa')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
																					</div>
																				</li>
																			</template>
																	</v-select>

																	<VValidate :name="`Diagnosa #${k+1}`" v-model="diagnosa[k].aranasd_diagnosa_id"
																			:rules="{required: 1}" />
															</div>
															<div class="col-6" v-if="diagnosa[k].aranasd_diagnosa_id">
																	<div class="card mb-0">
																			<div class="card-header bg_head_panel">
																					<h6 class="card-title font-weight-semibold">Penyebab</h6>
																			</div>
																			<div class="card-body p-3">
																					<b-form-checkbox-group
																							v-if="(diagnosa[k].aranasd_config_sdki.msd_penyebab||[]).length"
																							:id="'penyebab'+k" v-model="diagnosa[k].aranasd_penyebab"
																							:name="'penyebab'+k" stacked>
																							
																							<template v-for="(vpeny,kpeny) in (diagnosa[k].aranasd_config_sdki.msd_penyebab||[])">
																									<b-form-checkbox :value="vpeny.name"
																									@change="checkPenyebab(vpeny.name,k)" :key="kpeny"
																									>{{vpeny.name}}</b-form-checkbox>
																							</template>

																					</b-form-checkbox-group>
																					<span v-else> (Tidak Ada) </span>
																			</div>
																	</div>
															</div>
															<div class="col-6" v-if="diagnosa[k].aranasd_diagnosa_id">
																	<div class="card mb-0">
																			<div class="card-header bg_head_panel">
																					<h6 class="card-title font-weight-semibold">Faktor Resiko</h6>
																			</div>
																			<div class="card-body p-3">
																					<div class="row gx-4">
																							<div class="col-md-6">
																									<div class="signsCheck">
																											<b-form-checkbox-group
																													v-if="(diagnosa[k].aranasd_config_sdki.msd_faktor_resiko||[]).length"
																													v-model="diagnosa[k].aranasd_faktor_resiko"
																													:options="diagnosa[k].aranasd_config_sdki.msd_faktor_resiko"
																													:id="'faktor_resiko'+k" :name="'faktor_resiko'+k" value-field="name"
																													text-field="name" stacked></b-form-checkbox-group>
																											<span v-else> (Tidak Ada) </span>
																									</div>
																							</div>  
																					</div>
																			</div>
																	</div>
															</div>
															<div class="col-12" v-if="diagnosa[k].aranasd_diagnosa_id">
																	<div class="card mb-0">
																			<div class="card-header bg_head_panel">
																					<h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor</h6>
																			</div>
																			<div class="card-body p-3">
																					<div class="row gx-4">
																							<div class="col-md-6">
																									<div class="signsCheck">
																											<h6 class="font-weight-semibold">Subjective</h6>
																											<b-form-checkbox-group
																													v-if="(diagnosa[k].aranasd_config_sdki.msd_gejala_mayor_subjektif||[]).length"
																													v-model="diagnosa[k].aranasd_gejala_mayor_subjektif"
																													:options="diagnosa[k].aranasd_config_sdki.msd_gejala_mayor_subjektif"
																													:id="'mayor_sub'+k" :name="'mayor_sub'+k" value-field="name"
																													text-field="name" stacked></b-form-checkbox-group>
																											<span v-else> (Tidak Ada) </span>
																									</div>
																							</div>
																							<div class="col-md-6">
																									<div class="signsCheck">
																											<h6 class="font-weight-semibold">Objective</h6>
																											<b-form-checkbox-group
																													v-if="(diagnosa[k].aranasd_config_sdki.msd_gejala_mayor_objektif||[]).length"
																													v-model="diagnosa[k].aranasd_gejala_mayor_objektif"
																													:options="diagnosa[k].aranasd_config_sdki.msd_gejala_mayor_objektif"
																													:id="'mayor_obj'+k" :name="'mayor_obj'+k" value-field="name"
																													text-field="name" stacked></b-form-checkbox-group>
																											<span v-else> (Tidak Ada) </span>
																									</div>
																							</div>
																					</div>
																			</div>
																	</div>
															</div>
															<div class="col-12" v-if="diagnosa[k].aranasd_diagnosa_id">
																	<div class="card mb-0">
																			<div class="card-header bg_head_panel">
																					<h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor</h6>
																			</div>
																			<div class="card-body p-3">
																					<div class="row gx-4">
																							<div class="col-md-6">
																									<div class="signsCheck">
																											<h6 class="font-weight-semibold">Subjective</h6>
																											<b-form-checkbox-group
																													v-if="(diagnosa[k].aranasd_config_sdki.msd_gejala_minor_subjektif||[]).length"
																													v-model="diagnosa[k].aranasd_gejala_minor_subjektif"
																													:options="diagnosa[k].aranasd_config_sdki.msd_gejala_minor_subjektif"
																													:id="'minor_sbj'+k" :name="'minor_sbj'+k" value-field="name"
																													text-field="name" stacked></b-form-checkbox-group>
																											<span v-else> (Tidak Ada) </span>
																									</div>
																							</div>
																							<div class="col-md-6">
																									<div class="signsCheck">
																											<h6 class="font-weight-semibold">Objective</h6>
																											<b-form-checkbox-group
																													v-if="(diagnosa[k].aranasd_config_sdki.msd_gejala_minor_objektif||[]).length"
																													v-model="diagnosa[k].aranasd_gejala_minor_objektif"
																													:options="diagnosa[k].aranasd_config_sdki.msd_gejala_minor_objektif"
																													:id="'minor_obj'+k" :name="'minor_obj'+k" value-field="name"
																													text-field="name" stacked></b-form-checkbox-group>
																											<span v-else> (Tidak Ada) </span>
																									</div>
																							</div>
																					</div>
																			</div>
																	</div>
															</div>

															<div class="col-12">
																	<div class="card mb-0">
																			<div class="card-header bg_head_panel">
																					<h6 class="card-title font-weight-semibold">Luaran Keperawatan</h6>
																			</div>
																			<div class="card-header">
																					<ul class="nav nav-tabs nav-tabs-bottom mb-0">

																							<li class="nav-item" v-for="(v1,k1) in v.slki" :key="'Luaran'+k1">
																									<a href="javascript:;" @click="changeTabSLKI(k1+1,k)" data-toggle="tab"
																											:data-target="'#luarTab'+(k1+1)"
																											:class="v.aranasd_last_tab_slki == (k1+1) ? 'nav-link active' : 'nav-link'">
																											{{ v1.text || "Luaran Baru"}}
																											<i @click="removeLuaran(v1.aranasl_id,k,k1)"
																													class="icon-cross ml-2"></i> </a>
																							</li>

																							<li class="nav-item"><a href="javascript:;"
																									@click="addLuaran(v.aranasd_diagnosa_id, v.aranasd_aranas_id, v.aranasd_id, k)"
																									class="nav-link">
																									<i class="icon-plus2 mr-2"></i> Tambah Luaran
																							</a></li>

																					</ul>
																			</div>

																			<div class="tab-content">
																					<div v-for="(v1,k1) in v.slki" :key="k1+'lu'"
																							:class="v.aranasd_last_tab_slki == (k1+1) ? 'tab-pane fade show active':'tab-pane fade'"
																							:id="'#luarTab'+(k1+1)">
																							<div class="card-body">
																									<div class="row">
																											<div class="col-md-8">
																													<div>
																															<label for="diagnosa">Pilih Luaran</label>
																															<v-select placeholder="Pilih Luaran"
																																	class="diagnosa-section select-paging"
																																	v-model="diagnosa[k]['slki'][k1]['aranasl_diagnosa_luaran_id']"
																																	@search="filterSLKI($event,k,k1)"
																																	:options="getMasterSLKI(diagnosa[k]['mSlki'],diagnosa[k]['slki'],k1)"
																																	@input="selectedKriteriaHasil($event,k,k1)" label="text"
																																	:clearable="true" :reduce="v=>v.value">
																															
																																	<template slot="selected-option">
																																			<span v-b-tooltip.hover.right placement='right' :title="v1.text">{{ v1.text }}</span>
																																	</template>
																																	<template slot="option" slot-scope="option">
																																			<span v-b-tooltip.hover.right placement='right' :title="option.text">{{ option.text }}</span>
																																	</template>

																																	<template #list-footer>
																																			<li class="list-paging" v-if="ceilData(v1.totalRows/10)">
																																			<div class="d-flex align-items-center justify-content-between">
																																					<b-button @click="changePageSLKI(v1.currentPage,k,k1,'min','diagnosa','slki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
																																					<small>Halaman {{v1.currentPage}} dari {{ceilData(v1.totalRows/10)}}</small>
																																					<b-button @click="changePageSLKI(v1.currentPage,k,k1,'plus','diagnosa','slki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
																																			</div>
																																			</li>
																																	</template>
																															</v-select>

																													</div>
																													<VValidate :name="`Luaran Keperawatan #${k+1} #${k1+1}`"
																															v-model="diagnosa[k]['slki'][k1]['aranasl_diagnosa_luaran_id']"
																															:rules="{required: 1}" />
																											</div>
																											<div class="w-100"></div>
																											
																											<div class="col-md-3 mt-2">
																													<label for="luaranEkspektasi">Ekspektasi <span class="text-danger">*</span></label>
																													<v-select placeholder=" -- Pilih Salah Satu -- " v-model="diagnosa[k]['slki'][k1]['aranasl_ekspetasi']"  :options="Config.mr.mSLKIEkspetasi" label="text" :reduce="v=>v.value" :clearable="true"></v-select>

																													<VValidate :name="`Ekspektasi #${k+1} #${k1+1}`"
																															v-model="diagnosa[k]['slki'][k1]['aranasl_ekspetasi']"
																															:rules="{required: 1}" />
																											</div>

																											<div class="col-md-4 mt-2">
																												<div>
																													<label for="luaranWaktu">Kriteria Waktu<span class="text-danger">*</span></label>
																													<div class="input-group">
																														<b-form-input v-model="diagnosa[k]['slki'][k1]['aranasl_kriteria_waktu1']" type="text" class="form-control" :formatter="numberOnly" />
																														<div class="input-group-append input-group-prepend">
																															<span class="input-group-text">x</span>
																														</div>
																														<b-form-input v-model="diagnosa[k]['slki'][k1]['aranasl_kriteria_waktu2']" type="text" class="form-control" :formatter="numberOnly" />
																														<div class="input-group-append">
																															<span class="input-group-text">jam</span>
																														</div>
																													</div>
																													<small class="form-text text-secondary">Maks. 10x24 jam</small>
																												</div>

																												
																													<VValidate :name="`Kriteria Waktu 1 #${k+1} #${k1+1}`"
																															v-model="diagnosa[k]['slki'][k1]['aranasl_kriteria_waktu1']"
																															:rules="{required: 1, min_value: 1, max_value: 1, max_value:10}" />
																															
																													<VValidate :name="`Kriteria Waktu 2 #${k+1} #${k1+1}`"
																															v-model="diagnosa[k]['slki'][k1]['aranasl_kriteria_waktu2']"
																															:rules="{required: 1, min_value: 1, max_value: 1, max_value:24}" />
																											</div>
																									</div>
																							</div>
																							<table class="table table-bordered table-striped table-hover"
																									v-if="diagnosa[k]['slki'][k1]['aranasl_diagnosa_luaran_id']">
																									<thead>
																											<tr>
																													<th class="table-info" colspan="99">Kriteria Hasil</th>
																											</tr>
																											<tr>
																													<th>Kriteria</th>
																													<th colspan="5">Skor</th>
																													<th width="64">Aksi</th>
																											</tr>
																									</thead>
																									<tbody>
																											<tr v-for="(v11, k11) in (v1.aranasl_kriteria_hasil||[])"
																													:key="k11+'u'">
																													<td width="400">
																															<v-select placeholder="Pilih Kriteria"
																																	v-model="diagnosa[k]['slki'][k1].aranasl_kriteria_hasil[k11]['name']"
																																	:options="getMasterKriteriaHasil(diagnosa[k]['slki'][k1]['kriteriaHasil'],diagnosa[k]['slki'][k1].aranasl_kriteria_hasil,k11)
																									" label="mks_criteria_hasil" :clearable="true"
																																	:reduce="v=>v.mks_criteria_hasil"></v-select>


																															<VValidate
																																	:name="`Kriteria hasil #${k+1} #${k1+1} #${k11+1}`"
																																	v-model="diagnosa[k]['slki'][k1].aranasl_kriteria_hasil[k11]['name']"
																																	:rules="{required: 1, min: 2, max: 512}" />
																													</td>
																													<td width="64" v-for="(vc,kc) in Config.mr.kriteriaHasil"
																															:key="kc">
																															<div>
																																	<b-form-radio
																																			v-model="diagnosa[k]['slki'][k1].aranasl_kriteria_hasil[k11]['hasil']"
																																			:value="vc.value" :name="'scoringKrit'+k11"
																																			class="form-check-input-styled">{{vc.text}}
																																	</b-form-radio>
																															</div>
																													</td>
																													<td>
																															<a href="javascript:;" class="list-icons-item"
																																	@click="deleteKriteria(k,k1,k11)" data-toggle="tooltip"
																																	data-placement="top" title="Delete"><i
																																			class="icon-bin"></i></a>
																													</td>
																											</tr>
																									</tbody>
																									<tfoot>
																											<tr>
																													<td colspan="99" class="text-center">
																															<a href="javascript:;" @click="addNewKriteria(k,k1)"
																																	class="btn btn-outline alpha-blue text-blue-800 border-blue-600">
																																	<i class="icon-plus2 mr-2"></i> Tambah Kriteria
																															</a>
																													</td>
																											</tr>
																									</tfoot>
																							</table>
																					</div>
																			</div>

																	</div>
															</div>


															<div class="col-12">
																	<div class="card mb-0">
																			<div class="card-header bg_head_panel">
																					<h6 class="card-title font-weight-semibold">Intervensi Keperawatan</h6>
																			</div>
																			<div class="card-header">
																					<ul class="nav nav-tabs nav-tabs-bottom mb-0">

																							<li class="nav-item" v-for="(v2,k2) in v.siki" :key="'Intervensi'+k2">
																									<a href="javascript:;" @click="changeTabSIKI(k2+1,k)" data-toggle="tab"
																											:data-target="'#intvTab'+(k2+1)"
																											:class="v.aranasd_last_tab_siki == (k2+1) ? 'nav-link active' : 'nav-link'">
																											{{ v2.text || "Intervensi Baru"}}
																											<i @click="removeIntervensi(v2.aranasi_id,k,k2,diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id'])"
																													class="icon-cross ml-2"></i> </a>
																							</li>

																							<li class="nav-item"><a href="javascript:;"
																											@click="addIntervensi(v.aranasd_diagnosa_id, v.aranasd_aranas_id, v.aranasd_id, k)"
																											class="nav-link">
																											<i class="icon-plus2 mr-2"></i> Tambah Intervensi
																									</a></li>

																					</ul>
																			</div>
																			<div class="tab-content">
																					<div v-for="(v2,k2) in v.siki" :key="k2+'sik'"
																							:class="v.aranasd_last_tab_siki == (k2+1) ? 'tab-pane fade show active':'tab-pane fade'"
																							:id="'#intvTab1'+(k2+1)">
																							<div class="card-body">
																									<div class="row">
																											<div class="col-md-8">
																													<div>
																															<label for="diagnosa">Pilih Intervensi</label>
																															<v-select class="diagnosa-section select-paging" placeholder="Pilih Intervensi"
																																	@search="filterSIKI($event,k,k2)"
																																	v-model="diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id']"
																																	:options="getMasterSIKI(diagnosa[k]['mSiki'],diagnosa[k]['siki'],k2) "
																																	@input="selectedIntervensi($event,k,k2)" label="text"
																																	:clearable="true" :reduce="v=>v.value">
																																	
																																	<template slot="selected-option">
																																			<span v-b-tooltip.hover.right placement='right' :title="v2.text">{{ v2.text }}</span>
																																	</template>
																																	<template slot="option" slot-scope="option">
																																			<span v-b-tooltip.hover.right placement='right' :title="option.text">{{ option.text }}</span>
																																	</template>

																																	<template #list-footer>
																																			<li class="list-paging" v-if="ceilData(v2.totalRows/10)">
																																			<div class="d-flex align-items-center justify-content-between">
																																					<b-button @click="changePageSIKI(v2.currentPage,k,k2,'min','diagnosa','siki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
																																					<small>Halaman {{v2.currentPage}} dari {{ceilData(v2.totalRows/10)}}</small>
																																					<b-button @click="changePageSIKI(v2.currentPage,k,k2,'plus','diagnosa','siki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
																																			</div>
																																			</li>
																																	</template>
																															</v-select>

																													</div>

																													<VValidate :name="`Intervensi Keperawatan #${k+1} #${k2+1}`"
																															v-model="diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id']"
																															:rules="{required: 1}" />

																											</div>
																									</div>
																							</div>
																							<table
																									v-if="diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id']"
																									class="table table-bordered table-striped table-hover">
																									<thead>
																											<tr>
																													<th class="table-primary" colspan="99">Tindakan</th>
																											</tr>
																									</thead>
																									<tbody>
																											<tr>
																													<th colspan="2" class="table-light">OBSERVASI</th>
																											</tr>
																											<tr v-for="(vo,ko) in diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi']"
																													:key="ko+'ko'">
																													<td width="64">
																															<div class="form-check"><label class="form-check-label">
																																			<b-form-checkbox value="Y" :unchecked-value="null"
																																					v-model="diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi'][ko]['value']"
																																					@change="addTindakanKeperawtan($event,vo.tindakan,'OBSERVASI',k,('ko'+k2+ko),diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id'])"
																																					:name="'tinInt'+k2+ko"
																																					class="form-check-input-styled" />
																																	</label></div>
																													</td>
																													<td>{{vo.name}}</td>
																											</tr>
																									</tbody>
																									<tbody>
																											<tr>
																													<th colspan="2" class="table-light">TERAPEUTIK</th>
																											</tr>
																											<tr v-for="(vt,kt) in diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik']"
																													:key="kt+'kt'">
																													<td width="64">
																															<div class="form-check"><label
																																			class="form-check-label">
																																			<b-form-checkbox value="Y" :unchecked-value="null"
																																					v-model="diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik'][kt]['value']"
																																					@change="addTindakanKeperawtan($event,vt.tindakan,'TERAPEUTIK',k,('kt'+k2+kt),diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id'])"
																																					:name="'tinTer'+kt"
																																					class="form-check-input-styled" />
																																	</label></div>
																													</td>
																													<td>{{vt.name}}</td>
																											</tr>
																									</tbody>

																									<tbody>
																											<tr>
																													<th colspan="2" class="table-light">EDUKASI</th>
																											</tr>
																											<tr v-for="(ve,ke) in diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi']"
																													:key="ke+'ke'">
																													<td width="64">
																															<div class="form-check"><label class="form-check-label">
																																			<b-form-checkbox value="Y" :unchecked-value="null"
																																					v-model="diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi'][ke]['value']"
																																					@change="addTindakanKeperawtan($event,ve.tindakan,'EDUKASI',k,('ke'+k2+ke),diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id'])"
																																					:name="'tinEd'+ke"
																																					class="form-check-input-styled" />
																																	</label></div>
																													</td>
																													<td>{{ve.name}}</td>
																											</tr>
																									</tbody>

																									<tbody>
																											<tr>
																													<th colspan="2" class="table-light">KOLABORASI</th>
																											</tr>
																											<tr v-for="(vk,kk) in diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi']"
																													:key="kk+'kk'">
																													<td width="64">
																															<div class="form-check"><label      class="form-check-label">
																																			<b-form-checkbox value="Y" :unchecked-value="null"
																																					v-model="diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi'][kk]['value']"
																																					@change="addTindakanKeperawtan($event,vk.tindakan,'KOLABORASI',k,('kk'+k2+kk),diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id'])"
																																					:name="'tinKol'+kk"
																																					class="form-check-input-styled" />
																																	</label></div>
																													</td>
																													<td>{{vk.name}}</td>
																											</tr>
																									</tbody>
																							</table>
																					</div>
																			</div>
																	</div>
															</div>
															<div class="col-12">
																<label for="perawatMengisi">Perawat yang Mengisi <strong class="text-danger">*</strong></label>
																<b-form-input v-model="v.aranasd_nama_perawat" type="text" class="form-control" placeholder="Nama Perawat yang Mengisi" />
																<VValidate :name="`Perawat yang Mengisi`" v-model="v.aranasd_nama_perawat" :rules="{required: 1, min: 3, max: 128}" />
															</div>

															<div class="col-12">
																<div class="card border mb-0 shadow-0">
																	<div class="card-header bg-info">
																		<h5 class="card-title font-weight-semibold">Pelaksanaan</h5>
																	</div>
																	<table class="table table-sm table-input table-bordered">
																		<thead>
																			<tr>
																				<th>#</th>
																				<th>Pelaksanaan</th>
																				<th style="width:37%">Waktu</th>
																				<th>Perawat</th>
																				<th>Status</th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr v-for="(vti,kti) in (v.aranasd_tindakan_keperawatan||[])" :key="kti">
																				<td>
																					<b-form-checkbox :name="'tind-kep'+kti" @input="checkTindakan($event,vti,kti,k)" value="Y" v-model="vti.isChecked" :unchecked-value="'N'"  class="form-check-input-styled" />

																					<a href="javascript:;" @click="v.aranasd_tindakan_keperawatan.splice(kti,1)" class="text-danger-800 d-block" data-popup="tooltip" v-b-tooltip.hover title="Hapus Tindakan"><i class="icon-trash"></i></a>

																					<a href="javascript:;" @click="duplicatePelaksanaan(vti,k,kti)" class="text-info-800 mt-1 d-block" data-popup="tooltip" v-b-tooltip.hover title="Salin Tindakan"><i class="icon-file-plus mt-2"></i></a>
																				</td>
																				<td>
																					<div v-if="vti.idSiki != 'manual'">
																						<i v-if="vti.isChecked == 'Y'" class="icon-checkmark-circle text-success"></i>
																						<span class="align-middle ml-1">{{vti.name||"-"}}</span>
																					</div>
																					<div v-else class="d-flex">
																						<i v-if="vti.isChecked == 'Y'" class="icon-checkmark-circle text-success mr-2 mt-2"></i>
																						<b-form-textarea v-model="vti.name">
																						</b-form-textarea>
																					</div>
																				</td>
																				<td class="d-flex">
																					<template v-if="vti.isChecked == 'Y'">
																						<div class="input-group mt-2">
																								<datepicker @input="changePos(k)" input-class="form-control transparent"
																										placeholder="Tanggal" class="my-datepicker"
																										calendar-class="my-datepicker_calendar"
																										v-model="vti.dates">
																								</datepicker>
																								<div class="input-group-append calendar-group">
																										<span class="input-group-text" id="basic-addon2"><i
																														class="icon-calendar"></i></span>
																								</div>
																						</div>
																						<div class="input-group mt-2">
																								<vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="vti.time">
																								</vue-timepicker>
																								<div v-if="vti.time && !vti.time.HH && !vti.time.mm && vti.isChecked == 'Y'" id="Waktu"
																										class="label_error" style="">The Waktu. Kejadian field is required</div>
																						</div>

																						<VValidate v-if="vti.isChecked == 'Y'" :name="`Tanggal #`+(kti+1)" v-model="vti.dates" :rules="{required: 1}" />

																						<VValidate v-if="vti.isChecked == 'Y'" :name="`Waktu #`+(kti+1)" v-model="vti.time" :rules="{required: 1}" />
																					</template>
																					<span v-else> - </span>
																				</td>
																				<td>
																					<template v-if="vti.isChecked == 'Y'">
																						<b-form-input v-model="vti.ppa" type="text" class="form-control"/>
																						<VValidate v-if="vti.isChecked == 'Y'" :name="`Pelaksana #`+(kti+1)" v-model="vti.ppa" :rules="{required: 1}" />
																					</template>
																					<template v-else>
																						-
																					</template>
																				</td>
																				<td>
																					<span class="badge badge-success" v-if="vti.isChecked == 'Y'">Terlaksana</span>
																					<span class="badge badge-warning" v-else>Belum Terlaksana</span>

																				</td>
																			</tr>
																			<tr v-if="!(v.aranasd_tindakan_keperawatan||[]).length">
																					<td class="text-center" colspan="99">Tidak ada data</td>
																			</tr>
																		</tbody>
																		<tbody>
																			<tr>
																				<td colspan="99" class="text-center">
																				<button type="button" @click="addTindakanKeperawtanManual(k,('kk'+k2+kk))" class="btn btn-light">Tambah Pelaksanaan</button>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
															<div class="col-12">
																<div class="card border mb-0 shadow-0">
																	<div class="card-header bg-info">
																		<h5 class="card-title font-weight-semibold">Evaluasi</h5>
																	</div>
																	<div class="card-header bg-white">
																		<h6 class="card-title font-weight-semibold">Respon</h6>
																	</div>
																	<div class="table-responsive">
																		<table class="table table-bordered table-sm table-input">
																			<thead>
																				<tr>
																					<th rowspan="2" width="25%">Pelaksanaan</th>
																					<th colspan="3">Respon</th>
																				</tr>
																				<tr>
																					<th width="20%">S</th>
																					<th width="20%">O</th>
																					<th width="45%">Waktu</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr v-for="(vti,kti) in (v.aranasd_tindakan_keperawatan||[])" :key="kti">
																					<td>{{vti.name||"-"}}</td>
																					<td class="input-cell">
																						<b-form-textarea @input="autoFillSubjektifObj" v-model="vti.subjektif" rows="2" class="form-control" placeholder="Isi respon subjektif"></b-form-textarea>
																						<!--
																						<VValidate :name="`Subjektif #`+ (k+1)+(kti+1)" v-model="vti.subjektif" :rules="{required: 1, min: 3}" />
																						-->
																					</td>
																					<td class="input-cell">
																						<b-form-textarea @input="autoFillSubjektifObj" v-model="vti.objektif" rows="2" class="form-control" placeholder="Isi respon objektif"></b-form-textarea>
																						<!--
																						<VValidate :name="`Objektif #`+ (k+1)+(kti+1)" v-model="vti.objektif" :rules="{required: 1, min: 3}" />
																						-->
																					</td>
																					<td>
																						<div class="d-flex">
																							<div class="input-group mt-2">
																									<datepicker @input="autoFillSubjektifObj" input-class="form-control transparent"
																											placeholder="Tanggal" class="my-datepicker"
																											calendar-class="my-datepicker_calendar"
																											v-model="vti.tgl_response">
																									</datepicker>
																									<div class="input-group-append calendar-group">
																											<span class="input-group-text" id="basic-addon2"><i
																															class="icon-calendar"></i></span>
																									</div>
																							</div>

																							<div class="input-group mt-2">
																									<vue-timepicker manual-input @input="autoFillSubjektifObj" format="HH:mm" input-class="form-control" v-model="vti.waktu_response">
																									</vue-timepicker>
																							</div>
																						</div>
																						
																						<!--
																						<VValidate class="d-block" v-if="vti.isChecked == 'Y'" :name="`Tanggal #`+(kti+1)" v-model="vti.tgl_response" :rules="{required: 1}" />

																						<VValidate class="d-block" v-if="vti.isChecked == 'Y'" :name="`Waktu #`+(kti+1)" v-model="vti.waktu_response" :rules="{required: 1}" />
																						-->

																					</td>
																				</tr>
																				<tr v-if="!(v.aranasd_tindakan_keperawatan||[]).length">
																						<td class="text-center" colspan="99">Tidak ada data</td>
																				</tr>
																			</tbody>
																			<tbody>
																				<tr>
																					<td colspan="99" class="text-center">
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																	<div class="card-header bg-white">
																		<h6 class="card-title font-weight-semibold">Hasil</h6>
																	</div>
																	<div class="row p-2">
																		<div class="col-md-3">
																			<div class="form-group">
																				<label class="" for="">Tanggal Keluar Hasil</label>
																				<div class="input-group">
																					<datepicker input-class="form-control transparent"
																							placeholder="Tanggal" class="my-datepicker"
																							calendar-class="my-datepicker_calendar"
																							v-model="v.aranasd_hasil_date">
																					</datepicker>
																					<div class="input-group-append calendar-group">
																							<span class="input-group-text" id="basic-addon2"><i
																											class="icon-calendar"></i></span>
																					</div>
																				</div>
																				<!--
																				<VValidate :name="`Tanggal Keluar Hasil #`" v-model="v.aranasd_hasil_date"
																						:rules="{required: 1}" />
																				-->
																			</div>
																		</div>
																		<div class="col-md-3">
																			<div class="form-group">
																				<label class="" for="">Jam Keluar Hasil</label>
																				<div class="input-group">
																					<div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
																					<vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.aranasd_hasil_time">
																					</vue-timepicker>
																				</div>
																				<!--
																				<VValidate :name="`Jam Keluar Hasil #`+(k+1)" v-model="v.aranasd_hasil_time"
																						:rules="{required: 1}" />
																						-->
																			</div>
																		</div>
																	</div>
																	<div class="table-responsive">
																		<table class="table table-sm table-bordered">
																			<tbody>
																				<tr>
																					<td>
																						<div class="d-flex align-items-center">
																							<div class="label_code label_code_sm m-0 mr-2">
																								<h2>S</h2>
																							</div>
																							<span class="font-weight-semibold">Subjective</span>
																						</div>
																					</td>
																					<td>
																						<b-form-textarea v-model="v.aranasd_subjective" rows="3" class="form-control" placeholder="Hasil Evaluasi Subjective"></b-form-textarea>
																						<!--
																						<VValidate :name="`Hasil Evaluasi Subjective #`+(k+1)" v-model="v.aranasd_subjective" :rules="{required: 1, min: 3, max: 256}" />
																						-->
																					</td>
																				</tr>
																				<tr>
																					<td>
																						<div class="d-flex align-items-center">
																							<div class="label_code label_code_sm m-0 mr-2">
																								<h2>O</h2>
																							</div>
																							<span class="font-weight-semibold">Objective</span>
																						</div>
																					</td>
																					<td>
																						<b-form-textarea v-model="v.aranasd_objective" rows="3" class="form-control" placeholder="Hasil Evaluasi Objective"></b-form-textarea>
																						<!--
																						<VValidate :name="`Hasil Evaluasi Objective #`+(k+1)" v-model="v.aranasd_objective" :rules="{required: 1, min: 3, max: 256}" />
																						-->
																					</td>
																				</tr>
																				<tr>
																					<td>
																						<div class="d-flex align-items-center">
																							<div class="label_code label_code_sm m-0 mr-2">
																								<h2>A</h2>
																							</div>
																							<span class="font-weight-semibold">Assessment</span>
																						</div>
																					</td>
																					<td>
																						<v-select placeholder=" -- Pilih Salah Satu -- " v-model="v.aranasd_assesment"  :options="Config.mr.mSDKIAssesment" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
																						<!--
																						<VValidate :name="`Hasil Evaluasi Assesment #`+(k+1)" v-model="v.aranasd_objective" :rules="{required: 1, min: 3, max: 256}" />
																						-->
																					</td>
																				</tr>
																				<tr>
																					<td>
																						<div class="d-flex align-items-center">
																							<div class="label_code label_code_sm m-0 mr-2">
																								<h2>P</h2>
																							</div>
																							<span class="font-weight-semibold">Planning</span>
																						</div>
																					</td>
																					<td>
																						<b-form-radio-group
																								@input="changePlanning($event,k)"
																								:options="Config.mr.mSDKIPlanning"
																								v-model="v.aranasd_planing"
																						/>

																						<div class="card shadow-0 border mb-0 mt-2" v-if="v.aranasd_planing != 'H' && v.aranasd_planing">
																							<div class="card-header">
																								<ul class="nav nav-tabs nav-tabs-bottom mb-0">
																										<li class="nav-item" v-for="(v1,k1) in v.siki" :key="'Luaran Next'+k1">
																										<a href="javascript:;" @click="changeTabSIKINext(k1+1,k)"
																											:class="v.last_siki_next == (k1+1) ? 'nav-link active' : 'nav-link'">
																											{{ v1.text || "Luaran Baru"}}</a>
																										</li>
																								</ul>
																							</div>
																							<div class="tab-content">
																								<div v-for="(v2,k2) in v.siki" :key="k2+'in'"
																								:class="v.last_siki_next == (k2+1) ? 'tab-pane fade show active':'tab-pane fade d-none'">
																									<div class="card-body">
																										<div class="result_tab">
																											<h4>Nama Intervensi</h4>
																											<p>{{v2.text}}</p>
																										</div>
																									</div>
																									
																									<table
																											v-if="diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id']"
																											class="table table-bordered table-striped table-hover">
																											<thead>
																													<tr>
																															<th class="table-primary" colspan="99">Tindakan</th>
																													</tr>
																											</thead>
																											<tbody>
																													<tr>
																															<th colspan="2" class="table-light">OBSERVASI
																															</th>
																													</tr>
																													<tr v-for="(vo,ko) in diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi_next']"
																															:key="ko+'ko'">
																															<td width="64">
																																	<div class="form-check"><label class="form-check-label">
																																			<b-form-checkbox value="Y" :unchecked-value="null"
																																					v-model="diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi_next'][ko]['value']"
																																					@change="addTindakanKeperawtanNext($event,vo.tindakan,'OBSERVASI',k,('ko'+k2+ko),diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id'])"
																																					:name="'tinIntNext'+k2+ko"
																																					class="form-check-input-styled" />
																																	</label></div>
																															</td>
																															<td>{{vo.name}}</td>
																													</tr>
																											</tbody>
																											<tbody>
																													<tr>
																															<th colspan="2" class="table-light">TERAPEUTIK</th>
																													</tr>
																													<tr v-for="(vt,kt) in diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik_next']"
																															:key="kt+'kt'">
																															<td width="64">
																																	<div class="form-check"><label
																																					class="form-check-label">
																																					<b-form-checkbox value="Y" :unchecked-value="null"
																																							v-model="diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik_next'][kt]['value']"
																																							@change="addTindakanKeperawtanNext($event,vt.tindakan,'TERAPEUTIK',k,('kt'+k2+kt),diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id'])"
																																							:name="'tinTerNext'+k2+kt"
																																							class="form-check-input-styled" />
																																			</label></div>
																															</td>
																															<td>{{vt.name}}</td>
																													</tr>
																											</tbody>

																											<tbody>
																													<tr>
																															<th colspan="2" class="table-light">EDUKASI</th>
																													</tr>
																													<tr v-for="(ve,ke) in diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi_next']"
																															:key="ke+'ke'">
																															<td width="64">
																																	<div class="form-check"><label class="form-check-label">
																																					<b-form-checkbox value="Y" :unchecked-value="null"
																																							v-model="diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi_next'][ke]['value']"
																																							@change="addTindakanKeperawtanNext($event,ve.tindakan,'EDUKASI',k,('ke'+k2+ke),diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id'])"
																																							:name="'tinEdNext'+k2+ke"
																																							class="form-check-input-styled" />
																																			</label></div>
																															</td>
																															<td>{{ve.name}}</td>
																													</tr>
																											</tbody>

																											<tbody>
																													<tr>
																															<th colspan="2" class="table-light">KOLABORASI</th>
																													</tr>
																													<tr v-for="(vk,kk) in diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi_next']"
																															:key="kk+'kk'">
																															<td width="64">
																																	<div class="form-check"><label      class="form-check-label">
																																					<b-form-checkbox value="Y" :unchecked-value="null"
																																							v-model="diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi_next'][kk]['value']"
																																							@change="addTindakanKeperawtanNext($event,vk.tindakan,'KOLABORASI',k,('kk'+k2+kk),diagnosa[k]['siki'][k2]['aranasi_diagnosa_intervensi_id'])"
																																							:name="'tinKolNext'+k2+kk"
																																							class="form-check-input-styled" />
																																			</label></div>
																															</td>
																															<td>{{vk.name}}</td>
																													</tr>
																											</tbody>
																											<!--
																											<tbody>
																												<tr>
																													<th colspan="2" class="table-light">TINDAKAN LAINNYA</th>
																												</tr>
																											</tbody>
																											-->
																									</table>
																								</div>
																							</div>
																						</div>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																</div>
															</div>
														
													</div>
											</div>
									</div>
							</div>
					</div>

					<div class="card border shadow-0" v-if="rowData.isEdit">
							<div class="card-header bg-info">
									<h5 class="card-title font-weight-semibold">Keterangan Lainnya</h5>
							</div>
							<div class="card-body">
									<div class="form-group">
											<label for="addInfoTindakan">Keterangan Lainnya</label>
											<b-textarea v-model="rowData.aranas_notes" :formatter="normalText" rows="6" class="form-control"
													placeholder="Keterangan Lainnya">
											</b-textarea>
									</div>
							</div>
					</div>

				</div>
				<div class="card-footer">
          <div class="text-right">
            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
			</b-form>
    </validation-observer>

    <b-modal v-model="openFollowUpTindakan" :title="'Follow Up Tindakan Keperawatan'" size="sm" ok-title="Submit"
        @ok="submitFollowUp">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Follow Up Tindakan<small class="txt_mandatory">*</small></label>
                    <b-textarea v-model="rowFollowUp.followup" :formatter="normalText" rows="6" class="form-control"
                        placeholder="Informasi Tindakan" spellcheck="false"></b-textarea>
                </div>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default{
  extends: GlobalVue,
  components:{ Datepicker,VueTimepicker },
  methods: {
    back() {
      this.$router.back()
    },
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.isParent.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.askepNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.askepNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.isParent.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.isParent.loadingOverlay = false
      })

      // master
      if(this.$route.params.askepNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },

    checkPenyebab(e,k){
      this.diagnosa[k].aranasd_penyebab = []
      this.diagnosa[k].aranasd_penyebab = [e]
    },

    ceilData(value){
      return Math.ceil(value)
    },
    
    getMasterSDKI(master,diagnosa,k){
      const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
          return k2!=k
      }), v2=>{
          return v2.aranasd_diagnosa_id
      })
      return _.filter(master, v2=>{
          return sldMenu.indexOf(v2.value)<=-1
      })
    },

    getMasterSLKI(master,diagnosa,k){
      const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
          return k2!=k
      }), v2=>{
          return v2.aranasl_diagnosa_luaran_id
      })
      return _.filter(master, v2=>{
          return sldMenu.indexOf(v2.value)<=-1
      })
    },

    getMasterSIKI(master,diagnosa,k){
      const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
          return k2!=k
      }), v2=>{
          return v2.aranasi_diagnosa_intervensi_id
      })
      return _.filter(master, v2=>{
          return sldMenu.indexOf(v2.value)<=-1
      })
    },

    getMasterKriteriaHasil(master,kriteriaHasil,k){
      const sldMenu = _.map(_.filter(kriteriaHasil||[], (v2, k2)=>{
          return k2!=k
      }), v2=>{
          return v2.name
      })


      return _.filter(master, v2=>{
          return sldMenu.indexOf(v2.mks_criteria_hasil)<=-1
      })
    },

    addTindakanKeperawtan(e,name,type,k,id,idSiki){
      if(e){
					console.log(e+"satu aja")
          return this.diagnosa[k].aranasd_tindakan_keperawatan.push({
              name :`(${type}) ${name}`,
              dates : moment().format('YYYY-MM-DD'),
              time : {
                  'HH': moment().format('HH'),
                  'mm': moment().format('mm')
              },
              ppaLainnya: this.isParent.user.fullName,
              ppa : "",
              followup : "",
              isChecked : "N",
              id: id,
              idSiki: idSiki,
              objektif: "",
              subjektif: "",
							tgl_response: moment().format('YYYY-MM-DD'),
							waktu_response: moment().format('HH:mm')
          })
      }else{
					let filtered = this.diagnosa[k].aranasd_tindakan_keperawatan.filter(item => item.id !== id)
					this.diagnosa[k].aranasd_tindakan_keperawatan = filtered

          // let index = this.diagnosa[k].aranasd_tindakan_keperawatan.findIndex(x=>x.id == id)
          // if(index !== -1){
          //     this.diagnosa[k].aranasd_tindakan_keperawatan.splice(index, 1)
          // }
      }
    },

    addTindakanKeperawtanManual(k,id){
				console.log(id)
				this.diagnosa[k].aranasd_tindakan_keperawatan.push({
					name :``,
					dates : moment().format('YYYY-MM-DD'),
					time : {
							'HH': moment().format('HH'),
							'mm': moment().format('mm')
					},
					ppaLainnya: this.isParent.user.fullName,
					ppa : "",
					followup : "",
					isChecked : "N",
					id: id,
					idSiki: "manual",
					objektif: "",
					subjektif: "",
					tgl_response: moment().format('YYYY-MM-DD'),
					waktu_response: moment().format('HH:mm')
			})
		},

		
		duplicatePelaksanaan(val,k,key){
			this.diagnosa[k].aranasd_tindakan_keperawatan.splice(key+1,0,{
				name : val.name,
				dates : val.dates,
				time : {
						'HH': val.time.HH,
						'mm': val.time.mm
				},
				ppaLainnya: val.ppaLainnya,
				ppa : val.ppa,
				followup : val.followup,
				isChecked : "N",
				id: val.id,
				idSiki: val.idSiki,
				objektif: val.objektif,
				subjektif: val.subjektif,
				tgl_response: moment().format('YYYY-MM-DD'),
				waktu_response: moment().format('HH:mm')
			})
		},
    
    checkTindakan(e,vti,kti,k){
        if(e == "Y"){
            this.diagnosa[k].aranasd_tindakan_keperawatan[kti]['ppa'] = this.isParent.user.fullName
						this.diagnosa[k].aranasd_tindakan_keperawatan[kti]['dates'] = moment().format('YYYY-MM-DD')
						this.diagnosa[k].aranasd_tindakan_keperawatan[kti]['time'] = {
							'HH': moment().format('HH'),
							'mm': moment().format('mm')
						}
        }else{
            this.diagnosa[k].aranasd_tindakan_keperawatan[kti]['ppa'] = null
        }
    },
    
    changePos(k){
      let sortedObjs = _.sortBy(this.diagnosa[k].aranasd_tindakan_keperawatan, 'dates')
      this.diagnosa[k].aranasd_tindakan_keperawatan = sortedObjs
    },

    toValidate(val){
      return {...val}
    },

    changeTab(k){
        this.isActiveTab = k
    },

    changeTabSLKI(k1,k){
        this.diagnosa[k].aranasd_last_tab_slki = k1
        this.autoSave(this.isParent.rowData)
    },
    
    changeTabSIKI(k2,k){
        this.diagnosa[k].aranasd_last_tab_siki = k2
        this.autoSave(this.isParent.rowData)        
    },
    
    addNewKriteria(k,k1){
        this.diagnosa[k]['slki'][k1]['aranasl_kriteria_hasil'].push({
            hasil : 1,
            name : ''  
        })
    },
    deleteKriteria(k,k1,k11){
        this.diagnosa[k]['slki'][k1]['aranasl_kriteria_hasil'].splice(k11,1)
    },

    addNewDiagnosa(){
        this.isParent.loadingOverlay = true
        let data = {
            aranasd_aranas_id: this.rowData.aranas_id,
            type: 'add-diagnosa'
        }
        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            resp.row.aranasd_last_tab_slki = 0
            resp.row.aranasd_last_tab_siki = 0
            resp.row.last_siki_next = 0

            this.diagnosa.push(resp.row)
            this.isActiveTab = this.diagnosa.length
            this.isParent.loadingOverlay = false
        })
    },
    addLuaran(diagnosa, aranas_id, aranasd_id, k){
        this.isParent.loadingOverlay = true
        let data = {
          aranasl_diagnosa_id: diagnosa,
          aranasl_aranasd_id: aranasd_id,
          aranasl_aranas_id: aranas_id,
          aranasl_kriteria_hasil: [],
            type: 'add-luaran'
        }
        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            this.diagnosa[k]['slki'].push(resp.row)
            this.diagnosa[k]['aranasd_last_tab_slki'] = resp.countBySDKI
            this.diagnosa[k]['mSlki'] = resp.mSlki
            this.isParent.loadingOverlay = false
        })
    },
    addIntervensi(diagnosa, aranas_id, aranasd_id, k){
        this.isParent.loadingOverlay = true
        let data = {
            aranasi_diagnosa_id: diagnosa,
            aranasi_aranasd_id: aranasd_id,
            aranasi_aranas_id: aranas_id,
            aranasi_tindakan_observasi: [],
            aranasi_tindakan_terapeutik: [],
            aranasi_tindakan_edukasi: [],
            aranasi_tindakan_kolaborasi: [],
            aranasi_data: [],
            aranasi_tindakan_tambahan: [],

            type: 'add-intervensi'
        }
        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            this.diagnosa[k]['siki'].push(resp.row)
            this.diagnosa[k]['aranasd_last_tab_siki'] = resp.countBySDKI
            this.diagnosa[k]['last_siki_next'] = resp.countBySDKI
            this.diagnosa[k]['mSiki'] = resp.mSiki
            this.isParent.loadingOverlay = false
        })
    },
    selectedIntervensi(e,k,k2){
        let index = this.diagnosa[k]['mSiki'].findIndex(x=>x.value == e)
        if(index !== -1){            
            this.diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_observasi']))
            
            this.diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_terapeutik']))
            
            this.diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_edukasi']))
            
            this.diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_kolab']))

						
            this.diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_observasi']))
            
            this.diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_terapeutik']))
            
            this.diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_edukasi']))
            
            this.diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_kolab']))

            this.diagnosa[k]['siki'][k2]['text'] = this.diagnosa[k]['mSiki'][index]['text']
        }
    },

    removeDiagnosa(id,k){
        this.isParent.loadingOverlay = true
        let data = {
            id: id,
            type: 'remove-diagnosa'
        }
        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(()=>{
            this.diagnosa.splice(k,1)
            this.isActiveTab = this.diagnosa.length
            this.isParent.loadingOverlay = false
        })
    },

    removeLuaran(id,k,k1){
        this.isParent.loadingOverlay = true
        let data = {
            id: id,
            type: 'remove-luaran'
        }
        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(()=>{
            this.diagnosa[k]['slki'].splice(k1,1)
            this.isParent.loadingOverlay = false
            this.diagnosa[k]['aranasd_last_tab_slki'] = this.diagnosa[k]['slki'].length   

        })
    },

    removeIntervensi(id,k,k2,intervensiId){
        this.isParent.loadingOverlay = true
        
        let dataTindakan = _.filter(this.diagnosa[k].aranasd_tindakan_keperawatan, v => {
            return v.idSiki == intervensiId
        })
        
        for(let i = 0; i < (dataTindakan||[]).length; i++){
            let index = this.diagnosa[k].aranasd_tindakan_keperawatan.findIndex(x=>x.idSiki == intervensiId)
            if(index !== -1){
                this.diagnosa[k].aranasd_tindakan_keperawatan.splice(index, 1)
            }
        }

        let data = {
            id: id,
            type: 'remove-intervensi'
        }
        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(()=>{
            this.diagnosa[k]['siki'].splice(k2,1)
            this.isParent.loadingOverlay = false
            this.diagnosa[k]['aranasd_last_tab_siki'] = this.diagnosa[k]['siki'].length   
            this.diagnosa[k]['last_siki_next'] = this.diagnosa[k]['siki'].length   
        })
    },

    getLabelSDKI(data = null){
        if(data){
            let index = this.mSDKI.findIndex(x=>x.value == data)
            if(index !== -1){
                data = this.mSDKI[index]['text']
            }
        }
        return data
    },

    selectPenyebab(e,k){
        if(e){
            let index = this.mSDKI.findIndex(x=>x.value == e)
            if(index !== -1){
                this.diagnosa[k]['aranasd_config_sdki'] = this.mSDKI[index]
                this.diagnosa[k]['text'] = this.mSDKI[index]['text']
								this.diagnosa[k].aranasd_tindakan_keperawatan = []
								this.diagnosa[k].aranasd_tindakan_keperawatan_next = []

                
                for(let i = 0; i < (this.diagnosa[k]['slki']||[]).length; i++){
                    this.removeLuaran(this.diagnosa[k]['slki'][i].aranasl_id,k,i)                  
                    
                }
                
                for(let i = 0; i < (this.diagnosa[k]['siki']||[]).length; i++){
                    this.removeIntervensi(this.diagnosa[k]['siki'][i].aranasi_id,k,i)
                }
                
            }
        }
    },

    getLabelSLKI(data,id){
        let text = ""
        if(id){
            let index = data.findIndex(x=>x.value == id)
            if(index !== -1){
                text = data[index]['text']
            }
        }
        return text
    },
    

    getLabelSIKI(data,id){
        let text = ""
        if(id){
            let index = data.findIndex(x=>x.value == id)
            if(index !== -1){
                text = data[index]['text']
            }
        }
        return text
    },
    openDetailFollowUp(v,k,kP){
        this.openFollowUpTindakan = true,
        this.rowFollowUp = v
        this.rowFollowUp.key = k
        this.rowFollowUp.keyParent = kP
    },

    submitFollowUp(){
        this.diagnosa[this.rowFollowUp.keyParent]['aranasd_tindakan_keperawatan'][this.rowFollowUp.key]['followup'] = this.rowFollowUp.followup
    },

    selectedKriteriaHasil(e,k,k1){
        let index = this.diagnosa[k]['mSlki'].findIndex(x=>x.value == e)
        if(index !== -1){            
            this.diagnosa[k]['slki'][k1]['text'] = this.diagnosa[k]['mSlki'][index]['text']
        }

        let data = {
            id: e,
            type: 'get-kriteria-hasil'
        }
        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            this.diagnosa[k]['slki'][k1]['kriteriaHasil'] = res.data.mKriteriaHasil            
        })
    },

    changePageSDKI(page,k,act,col){
        if(act == 'min'){
            if(page > 1){
                page -= 1
                this.isParent[col][k]['currentPage'] -= 1 
            }
        }else{
            if(page < this.ceilData(this.isParent[col][k]['totalRows']/10)){
                page += 1
                this.isParent[col][k]['currentPage'] += 1 
            }
        }
        let data = {
            type : 'select-paging-sdki',
            page : page,
            search : this.isParent[col][k]['search']
        }

        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            this.mSDKI = res.data.data
        })
    },
    
    filterSDKI: _.debounce(function (e,k){
        if(e){
            this.diagnosa[k]['search'] = e         
            Gen.apiRest('/do/'+this.isParent.modulePage, {data: {type:'select-paging-sdki', search: e}}, 'POST').then(res=>{
                this.mSDKI = res.data.data
                this.diagnosa[k]['currentPage'] = 1
                this.diagnosa[k]['totalRows'] = res.data.totalRows
            })
        }
    },10),

    
    changePageSLKI(page,k,k1,act,col,diagnosa){
          if(act == 'min'){
            if(page > 1){
                page -= 1
                this.isParent[col][k][diagnosa][k1]['currentPage'] -= 1 
            }
        }else{
            if(page < this.ceilData(this.isParent[col][k][diagnosa][k1]['totalRows']/10)){
                page += 1
                this.isParent[col][k][diagnosa][k1]['currentPage'] += 1 
            }
        }
        let data = {
            type : 'select-paging-slki',
            page : page,
            diagnosa_id: this.isParent[col][k]['aranasd_diagnosa_id'],
            search : this.isParent[col][k]['search']
        }

        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            this.isParent.mSLKI = res.data.data
        })
    },

    filterSLKI: _.debounce(function (e,k,k1){
        if(e){
            this.diagnosa[k]['slki'][k1]['search'] = e         
            Gen.apiRest('/do/'+this.isParent.modulePage, {data: {type:'select-paging-slki', search: e, diagnosa_id: this.isParent['diagnosa'][k]['aranasd_diagnosa_id']}}, 'POST').then(res=>{
                this.isParent.mSLKI = res.data.data
                this.diagnosa[k]['slki'][k1]['currentPage'] = 1
                this.diagnosa[k]['slki'][k1]['totalRows'] = res.data.totalRows
            })
        }
    },10),

    changePageSIKI(page,k,k2,act,col,diagnosa){
          if(act == 'min'){
            if(page > 1){
                page -= 1
                this.isParent[col][k][diagnosa][k2]['currentPage'] -= 1 
            }
        }else{
            if(page < this.ceilData(this.isParent[col][k][diagnosa][k2]['totalRows']/10)){
                page += 1
                this.isParent[col][k][diagnosa][k2]['currentPage'] += 1 
            }
        }
        let data = {
            type : 'select-paging-siki',
            page : page,
            diagnosa_id: this.isParent[col][k]['aranasd_diagnosa_id'],
            search : this.isParent[col][k]['search']
        }

        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            this.isParent.mSIKI = res.data.data
        })
    },
    
    filterSIKI: _.debounce(function (e,k,k2){
        if(e){
            this.diagnosa[k]['siki'][k2]['search'] = e         
            Gen.apiRest('/do/'+this.isParent.modulePage, {data: {type:'select-paging-siki', search: e, diagnosa_id: this.isParent['diagnosa'][k]['aranasd_diagnosa_id']}}, 'POST').then(res=>{
                this.isParent.mSIKI = res.data.data
                this.diagnosa[k]['siki'][k2]['currentPage'] = 1
                this.diagnosa[k]['siki'][k2]['totalRows'] = res.data.totalRows
            })
        }
    },10),
    
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      
      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.isParent.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

		autoFillSubjektifObj(){
			
			console.log("hore")

			for(let i = 0; i < (this.diagnosa||[]).length; i++){
				let resultSubjektif = []
				let resultObjektif = []
	      for(let j = 0; j < (this.diagnosa[i].aranasd_tindakan_keperawatan||[]).length; j++){
					let waktu = moment(this.diagnosa[i].aranasd_tindakan_keperawatan[j].tgl_response).format('DD MMM YYYY') + " " + this.diagnosa[i].aranasd_tindakan_keperawatan[j].waktu_response

					if(this.diagnosa[i].aranasd_tindakan_keperawatan[j].subjektif){
						resultSubjektif.push("- "+this.diagnosa[i].aranasd_tindakan_keperawatan[j].subjektif)
					}
					if(this.diagnosa[i].aranasd_tindakan_keperawatan[j].objektif){
						resultObjektif.push("- "+this.diagnosa[i].aranasd_tindakan_keperawatan[j].objektif)
					}
				}	
				this.diagnosa[i]['aranasd_subjective'] = resultSubjektif.join("\n")
				this.diagnosa[i]['aranasd_objective'] = resultObjektif.join("\n")
			}
		},

    autoSaveDiagnosa: _.debounce(function (data) {
			
      let dataPost = {
          type : 'auto-save-diagnosa',
          data : data
      }  
      if(!this.rowData.isEdit){
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data:dataPost}, 
              "POST"
          )
      }
    },1000),

    
    changeTabSIKINext(k1,k){
			this.diagnosa[k].last_siki_next = k1
    },  

		changePlanning(e,k){
			if(e == 'LI'){
        for(let i = 0; i < (this.diagnosa[k]['siki']||[]).length; i++){
					
					let aranasi_tindakan_observasi_next = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_observasi']))
					let new_aranasi_tindakan_observasi_next = []
					for(let j = 0; j < (aranasi_tindakan_observasi_next||[]).length; j++){
						if(aranasi_tindakan_observasi_next[j]['value'] == 'Y'){
							new_aranasi_tindakan_observasi_next.push(aranasi_tindakan_observasi_next[j])
						}
					}
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_observasi_next']  = new_aranasi_tindakan_observasi_next

					let aranasi_tindakan_terapeutik_next = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_terapeutik']))
					let new_aranasi_tindakan_terapeutik_next = []
					for(let j = 0; j < (aranasi_tindakan_terapeutik_next||[]).length; j++){
						if(aranasi_tindakan_terapeutik_next[j]['value'] == 'Y'){
							new_aranasi_tindakan_terapeutik_next.push(aranasi_tindakan_terapeutik_next[j])
						}
					}
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_terapeutik_next']  = new_aranasi_tindakan_terapeutik_next

					let aranasi_tindakan_edukasi_next = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_edukasi']))
					let new_aranasi_tindakan_edukasi_next = []
					for(let j = 0; j < (aranasi_tindakan_edukasi_next||[]).length; j++){
						if(aranasi_tindakan_edukasi_next[j]['value'] == 'Y'){
							new_aranasi_tindakan_edukasi_next.push(aranasi_tindakan_edukasi_next[j])
						}
					}
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_edukasi_next']  = new_aranasi_tindakan_edukasi_next

					let aranasi_tindakan_kolaborasi_next = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_kolaborasi']))
					let new_aranasi_tindakan_kolaborasi_next = []
					for(let j = 0; j < (aranasi_tindakan_kolaborasi_next||[]).length; j++){
						if(aranasi_tindakan_kolaborasi_next[j]['value'] == 'Y'){
							new_aranasi_tindakan_kolaborasi_next.push(aranasi_tindakan_kolaborasi_next[j])
						}
					}
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_kolaborasi_next']  = new_aranasi_tindakan_kolaborasi_next


					// this.diagnosa[k]['siki'][i]['aranasi_tindakan_observasi_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_observasi']))
					// this.diagnosa[k]['siki'][i]['aranasi_tindakan_terapeutik_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_terapeutik']))
					// this.diagnosa[k]['siki'][i]['aranasi_tindakan_edukasi_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_edukasi']))
					// this.diagnosa[k]['siki'][i]['aranasi_tindakan_kolaborasi_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_kolaborasi']))
				}
			}else if(e == 'LS'){
				for(let i = 0; i < (this.diagnosa[k]['siki']||[]).length; i++){
				
					let aranasi_tindakan_observasi_next = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_observasi']))
					let new_aranasi_tindakan_observasi_next = []
					for(let j = 0; j < (aranasi_tindakan_observasi_next||[]).length; j++){
						if(aranasi_tindakan_observasi_next[j]['value'] == 'Y'){
							aranasi_tindakan_observasi_next[j]['value'] = "N"
							new_aranasi_tindakan_observasi_next.push(aranasi_tindakan_observasi_next[j])
						}
					}
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_observasi_next']  = new_aranasi_tindakan_observasi_next

					let aranasi_tindakan_terapeutik_next = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_terapeutik']))
					let new_aranasi_tindakan_terapeutik_next = []
					for(let j = 0; j < (aranasi_tindakan_terapeutik_next||[]).length; j++){
						if(aranasi_tindakan_terapeutik_next[j]['value'] == 'Y'){
							aranasi_tindakan_terapeutik_next[j]['value'] = "N"
							new_aranasi_tindakan_terapeutik_next.push(aranasi_tindakan_terapeutik_next[j])
						}
					}
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_terapeutik_next']  = new_aranasi_tindakan_terapeutik_next

					let aranasi_tindakan_edukasi_next = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_edukasi']))
					let new_aranasi_tindakan_edukasi_next = []
					for(let j = 0; j < (aranasi_tindakan_edukasi_next||[]).length; j++){
						if(aranasi_tindakan_edukasi_next[j]['value'] == 'Y'){
							aranasi_tindakan_edukasi_next[j]['value'] = "N"
							new_aranasi_tindakan_edukasi_next.push(aranasi_tindakan_edukasi_next[j])
						}
					}
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_edukasi_next']  = new_aranasi_tindakan_edukasi_next

					let aranasi_tindakan_kolaborasi_next = JSON.parse(JSON.stringify(this.diagnosa[k]['siki'][i]['aranasi_tindakan_kolaborasi']))
					let new_aranasi_tindakan_kolaborasi_next = []
					for(let j = 0; j < (aranasi_tindakan_kolaborasi_next||[]).length; j++){
						if(aranasi_tindakan_kolaborasi_next[j]['value'] == 'Y'){
							aranasi_tindakan_kolaborasi_next[j]['value'] = "N"
							new_aranasi_tindakan_kolaborasi_next.push(aranasi_tindakan_kolaborasi_next[j])
						}
					}
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_kolaborasi_next']  = new_aranasi_tindakan_kolaborasi_next

					// this.diagnosa[k]['siki'][i]['aranasi_tindakan_observasi_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][i]['msi_tindakan_perawat_observasi']))
					// this.diagnosa[k]['siki'][i]['aranasi_tindakan_terapeutik_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][i]['msi_tindakan_perawat_terapeutik']))
					// this.diagnosa[k]['siki'][i]['aranasi_tindakan_edukasi_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][i]['msi_tindakan_perawat_edukasi']))
					// this.diagnosa[k]['siki'][i]['aranasi_tindakan_kolaborasi_next'] = JSON.parse(JSON.stringify(this.diagnosa[k]['mSiki'][i]['msi_tindakan_perawat_kolab']))
				}
			}else{
				for(let i = 0; i < (this.diagnosa[k]['siki']||[]).length; i++){
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_observasi_next'] = []
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_terapeutik_next'] = []
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_edukasi_next'] = []
					this.diagnosa[k]['siki'][i]['aranasi_tindakan_kolaborasi_next'] = []
				}
			}
		},
		
    addTindakanKeperawtanNext(e,name,type,k,id,idSiki){
      if(e){
          this.diagnosa[k].aranasd_tindakan_keperawatan_next.push({
              name :`(${type}) ${name}`,
              dates : moment().format('YYYY-MM-DD'),
              time : {
                  'HH': moment().format('HH'),
                  'mm': moment().format('mm')
              },
              ppaLainnya: this.isParent.user.fullName,
              ppa : "",
              followup : "",
              isChecked : "N",
              id: id,
              idSiki: idSiki,
              objektif: "",
              subjektif: "",
							tgl_response: moment().format('YYYY-MM-DD'),
							waktu_response: moment().format('HH:mm')
          })
      }else{
          let index = this.diagnosa[k].aranasd_tindakan_keperawatan_next.findIndex(x=>x.id == id)
          if(index !== -1){
              this.diagnosa[k].aranasd_tindakan_keperawatan_next.splice(index, 1)
          }
      }
    },

		doSubmit(){
			if(this.rowData.aranas_is_active == 'N'){
				return this.$swal({
						icon: 'error',
						title: 'Data Telah tidak Aktif',
						text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
				}).then(result => {
						if (result.value) {
								setTimeout(()=>{
								let inv = []
								let el = document.querySelectorAll('.label_error')
								for(let i = 0; i < (el||[]).length; i++){
								if(el[i].style.display !== 'none'){
										inv.push(el[i].id)
								}
								}
								if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
						},500)
						}
				})
			}   
			if(!(this.diagnosa||[]).length){
					return this.$swal({
							icon: 'error',
							title: 'Minimal harus ada 1 diagnosa'
					}).then(result => {
							if (result.value) {
								setTimeout(()=>{
										let inv = []
										let el = document.querySelectorAll('.label_error')
										for(let i = 0; i < (el||[]).length; i++){
										if(el[i].style.display !== 'none'){
												inv.push(el[i].id)
										}
										}
										if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
								},500)
							}
					})
			}   

			let invalSiki = 0
			let invalSikiTab = []

			let invalSlki = 0
			let invalSlkiTab = []

			for(let i = 0; i < (this.diagnosa||[]).length; i++){
					if(!(this.diagnosa[i]['siki']||[]).length){
							invalSiki += 1
							invalSikiTab.push(i+1)
					}
					if(!(this.diagnosa[i]['slki']||[]).length){
							invalSlki += 1
							invalSlkiTab.push(i+1)
					}
			}
			
			
			if(invalSlki){
					return this.$swal({
							icon: 'error',
							title: `SLKI Pada Tab Ke ${invalSlkiTab.join(', ')} Kosong`
					}).then(result => {
							if (result.value) {
								setTimeout(()=>{
									let inv = []
									let el = document.querySelectorAll('.label_error')
									for(let i = 0; i < (el||[]).length; i++){
										if(el[i].style.display !== 'none'){
												inv.push(el[i].id)
										}
										}
										if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
								},500)
							}
					})
			}   
			
			if(invalSiki){
				return this.$swal({
						icon: 'error',
						title: `SIKI Pada Tab Ke ${invalSikiTab.join(', ')} Kosong`
				}).then(result => {
					if (result.value) {
						setTimeout(()=>{
								let inv = []
								let el = document.querySelectorAll('.label_error')
								for(let i = 0; i < (el||[]).length; i++){
								if(el[i].style.display !== 'none'){
										inv.push(el[i].id)
								}
								}
								if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
						},500)
					}
				})
			}   

			let hasilPelaksana = 0
			for(let i = 0; i < (this.diagnosa||[]).length; i++){
				// hasilPelaksana = 0
				for(let j = 0; j < (this.diagnosa[i]['aranasd_tindakan_keperawatan']||[]).length; j++){
					if(this.diagnosa[i]['aranasd_tindakan_keperawatan'][j]['isChecked'] == "Y"){
						hasilPelaksana += 1
					}
				}	
				// if(hasilPelaksana != (this.diagnosa[i]['aranasd_tindakan_keperawatan']||[]).length){
				// 	return this.$swal({
				// 			icon: 'error',
				// 			title: `Pelaksanaan Belum Diisi`
				// 	})
				// }
			}

			// if(!hasilPelaksana){
			// 	return this.$swal({
			// 			icon: 'error',
			// 			title: `Pelaksanaan Belum Diisi`
			// 	})
			// }

			this.$refs['VFormAskep'].validate().then(success=>{
				if(!success){
					return this.$swal({
						icon: 'error',
						title: 'Mohon Lengkapi Data Terlebih Dahulu'
					}).then(result => {
						if (result.value) {
							setTimeout(()=>{
								let inv = []
								let el = document.querySelectorAll('.label_error')
								for(let i = 0; i < (el||[]).length; i++){
										if(el[i].style.display !== 'none'){
												inv.push(el[i].id)
										}
								}
								if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
							},500)
						}
					})
				}else{
					this.$swal({
						icon: 'warning',
						title: 'Apakah Anda Yakin akan menyimpan data ini?',
						showCancelButton: true,
						confirmButtonText: 'Ya',
						cancelButtonText: 'Tidak, kembali'
					}).then(result => {
						 if (result.value) {
								let data = this.rowData
								data.type = 'submit-data'
								data.diagnosa = this.diagnosa
								
								this.isParent.loadingOverlay = true
								
								Gen.apiRest(
										"/do/"+this.isParent.modulePage,
										{data:data}, 
										"POST"
								).then(res=>{
										this.isParent.loadingOverlay = false
										let resp = res.data
										resp.statusType = 200
										this.$swal({
												title: resp.message,
												icon: resp.status,
												confirmButtonText: 'Ok',
												allowOutsideClick: false,
												allowEscapeKey: false
										}).then(result => {
												if (result.value) {
														this.$router.push({ name: this.isParent.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
												}
										})
								}).catch(err=>{
										this.isParent.loadingOverlay = false
										if(err){
												err.statusType = err.status
												err.status = "error"
												err.title = err.response?.data?.title
												err.message = err.response?.data?.message
												err.messageError = err.message
										}
										this.isParent.doSetAlertForm(err)
								})
						 }
						
					})
				}
			})
		}
  },
  data() {
    return {
      rowData: {},
      isActiveTab : null,
      openFollowUpTindakan : false,
      rowFollowUp: {} ,
      diagnosa: [],
      mSDKI: []
    }
  },
  mounted() {
    this.apiGetForm()  
    
    setTimeout(()=>{
        if((this.diagnosa||[]).length){
            this.isActiveTab = (this.diagnosa||[]).length
        }
    },1500)
  },
  computed: {
    isParent(){
      return this.$parent
    },
		
  },
  watch: {
    rowData: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    diagnosa: {
        handler(v) {
            this.autoSaveDiagnosa(v)
        },
        deep: true
    }
  }
}
</script>

