<template>  
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />
    <div class="content">
      <template v-if="isListDetail">
        <div class="page-title pt-0 pb-3">
          <a href="javascript:;" @click="$router.push({ name: 'RanapPasienPerawatan' })" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </a>
        </div>
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg-white">
                <div class="d-flex align-items-center">
                  <h5 class="card-title font-weight-semibold mr-auto">Asuhan Keperawatan Pasien</h5>
                  
                  <button @click="apiGet()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                    class="btn btn-light mr-2" title="Muat Ulang" v-b-tooltip.hover.left><i class="icon-spinner11"></i></button>

                  <a href="javascript:;" @click="addTindakan()" class="btn bg-blue-600 btn-labeled btn-labeled-left">
                    <b><i class="icon-plus2"></i></b>Tambah Data Asuhan</a>
                </div>
              </div>
              <div class="card-body" v-for="(v,k) in (dataAsuhanKeperawatan||[])" :key="k">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <div class="mr-2">
                        <div class="font-weight-semibold">{{v.aranas_date | moment('DD MMM YYYY')}} {{v.aranas_jam||"-"}}</div>
                        <small class="text-secondary">Oleh: {{v.created_by||"-"}}</small>
                      </div>
                      <a href="javascript:;" @click="toDetail(v)" class="btn btn-icon btn-sm rounded-round alpha-info border-info text-info-800" v-b-tooltip.hover title="Lihat Detail"><i class="icon-eye"></i></a>

                      <!-- v-if="v.isEdit" -->
                      <a href="javascript:;" v-if="v.isEdit" @click="toForm(v)" class="btn btn-icon btn-sm rounded-round alpha-info border-info text-info-800 ml-1" v-b-tooltip.hover title="Tambah Pelaksanaan"><i class="icon-pencil5"></i></a>
                      
                      <a href="javascript:;" @click="hapusAskep(v)" v-if="v.aranas_is_draft == 'Y'" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800 ml-1" v-b-tooltip.hover title="Hapus"><i class="icon-trash"></i></a>

                      <span v-if="v.aranas_is_draft == 'Y'" class="badge bg-warning ml-2 mt-1">Draft </span>

                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table table-bordered table-sm">
                      <tbody v-for="(v1,k1) in (v.diagnosa||[])" :key="k1+'diagnosa'">
                        <tr class="table-secondary">
                          <th colspan="3"><span class="font-weight-semibold mb-0">Diagnosa Keperawatan #{{(k1+1)}} : {{v1.msd_code
                          ||"-"}} - {{v1.msd_name||"-"}}</span>
                          <span v-if="showLabel(v1)" class="badge bg-warning ml-2 mt-1">Isi Hasil Diagnosa Keperawatan </span>

                          <span class="badge bg-warning ml-2 mt-1">
                          {{getConfigDynamic(Config.mr.mSDKIPlanning,v1.aranasd_planing)||"-"}} 
                          </span>

                          <span class="badge bg-info ml-2 mt-1">
                          {{getConfigDynamic(Config.mr.mSDKIAssesment,v1.aranasd_assesment)||"Belum ada hasil"}}
                          </span>

                          </th>
                        </tr>
                        <tr>
                          <td class="align-top">
                            <div class="result_tab">
                              <h4>Luaran Keperawatan</h4>
                              <ul class="mb-0 pl-3" v-if="(v1.slki||[]).length">
                                <li v-for="(vl,kl) in (v1.slki||[])" :key="kl+'l'">{{vl.msl_code||"-"}} - {{vl.msl_name||"-"}}</li>
                              </ul>
                              <span v-else>Tidak ada Data</span>
                            </div>
                          </td>
                          <td class="align-top">
                            <div class="result_tab">
                              <h4>Intervensi Keperawatan</h4>
                              <ul class="mb-0 pl-3" v-if="(v1.siki||[]).length">
                                <li v-for="(vi,ki) in (v1.siki||[])" :key="ki+'i'">{{vi.msi_code||"-"}} - {{vi.msi_name||"-"}}</li>
                              </ul>
                              <span v-else>Tidak ada Data</span>
                            </div>
                          </td>
                          <td class="align-top">
                            <div class="result_tab">
                              <h4>Pelaksanaan</h4>
                              <ul class="mb-0 pl-3" v-if="(v1.aranasd_tindakan_keperawatan||[]).length">
                                <li class="mb-1" v-for="(vp,kp) in (v1.aranasd_tindakan_keperawatan||[])" :key="kp+'p'">{{vp.name||"-"}}
                                <span class="badge badge-success" v-b-tooltip.hover.right 
                                :title="`Dilaksanakan Pada ${toDate(vp.dates)} 
                                ${vp.time.HH}:${vp.time.mm}, Oleh ${vp.ppa}`" v-if="vp.isChecked == 'Y'">Terlaksana</span>
                                <span class="badge badge-warning" v-else>Belum Terlaksana</span>
                                </li>
                              </ul>
                              <span v-else>Tidak ada Data</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="align-top" colspan="3">
                            <div class="font-weight-semibold mb-1">Evaluasi</div>
                            <table class="table table-bordered">
                              <tr>
                                <td class="align-top" width="50%">
                                  <div class="result_tab">
                                    <h4>Respon</h4>
                                    <ul class="mb-0 pl-3" v-for="(ve,ke) in (v1.aranasd_tindakan_keperawatan||[])" :key="ke+'ke'">
                                      <li>
                                        <div>{{ve.name||"-"}}</div>
                                        <div class="my-1">
                                          <span class="font-weight-semibold text-info-800">S: </span>{{ve.subjektif||"Belum ada hasil"}}
                                        </div>
                                        <div class="my-1">
                                          <span class="font-weight-semibold text-info-800">O: </span>{{ve.objektif||"Belum ada hasil"}}
                                        </div>
                                      </li>
                                    </ul>
                                    <span v-if="!(v1.aranasd_tindakan_keperawatan||[]).length">Tidak ada data</span>
                                  </div>
                                </td>
                                <td class="align-top" width="50%">
                                  <div class="result_tab">
                                    <h4>Hasil</h4>
                                    <span v-if="v.aranasd_hasil_date && v.aranasd_hasil_time">Keluar Pada : {{v.aranasd_hasil_date | moment("DD MMMM YYYY")}} {{v.aranasd_hasil_time}}</span>
                                    <ul class="mb-0 pl-3">
                                      <li><span class="font-weight-semibold text-info-800">S: </span>
                                      <pre class="pre-input mb-1">
                                      {{v1.aranasd_subjective||"Belum ada hasil"}}
                                      </pre>
                                      </li>
                                      <li><span class="font-weight-semibold text-info-800">O: </span>
                                      <pre class="pre-input mb-1">
                                      {{v1.aranasd_objective||"Belum ada hasil"}}
                                      </pre>
                                      </li>
                                      <li><span class="font-weight-semibold text-info-800">A: </span>{{getConfigDynamic(Config.mr.mSDKIAssesment,v1.aranasd_assesment)||"Belum ada hasil"}}</li>
                                      <li><span class="font-weight-semibold text-info-800">P: </span>{{getConfigDynamic(Config.mr.mSDKIPlanning,v1.aranasd_planing)||"Belum ada hasil"}}</li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-if="!(v.diagnosa||[]).length">
                        <tr class="table-secondary">
                          <th colspan="3"><span class="font-weight-semibold mb-0">Belum Ada Diagnosa Ditambahkan</span></th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <span v-if="!(dataAsuhanKeperawatan||[]).length" class="mt-2 text-center mb-3">Tidak ada Data</span>
            </div>
          </div>
        </div>

        
        
        <b-modal
          v-model="openDetail"
          :size="'xl'"
          no-close-on-backdrop
          hide-footer
          :title="'Detail Asuhan Keperawatan Pasien'" 
        >
          <FormDetail />     
        </b-modal>
      </template>

      <template v-else>
        <Form /> 
      </template>
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import Form from './Form.vue'
  import FormDetail from './FormDetail.vue'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  const moment = require('moment')

  export default {
    extends: GlobalVue,
    components:{Form,FormDetail,PatientInfo},
    data() {
      return {
        rowReg: {},
        dataAsuhanKeperawatan: [],
        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,
        openDetail: false,
        
        patientData: {},
        loading: {
          patientInfo: false,
        }
      }
    },
    computed: {
      isListDetail(){ return !this.$route.params.askepNo },  
      formName(){ return this.$route.query.form },    
    },
    methods: {
      toDate(dates){
        return moment(dates).format('DD MMMM YYYY')
      },
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
          "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
          },
          "POST"
        ).then(resp => {
          this.$set(this, 'patientData', resp.data.data)

          this.$set(this.loading, 'patientInfo', false)
        })
      },
      showLabel(v){
        return moment().format('YYYY-MM-DD HH:mm:ss') >= v.aranasd_expired && v.aranasd_is_closed != "Y" 
      },
      getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
      },
      apiGet(params = {}, page = 1) {
        if (!this.pageSlug) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })
      },
      
      addTindakan(){
        this.loadingOverlay = true
        let data = {
          regId : this.rowReg.aranr_id
        }
        data.type = 'init-askep'
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            this.loadingOverlay = false
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, askepNo: resp.aranas_id}})
            .catch(()=>{})
        })
      },

      toDetail(row){
        this.openDetail = true
        this.rowEdit = row
      },
      toForm(row,form=''){
        if(form){
          this.$router.push({ 
            name: this.modulePage, 
            params: {pageSlug: this.rowReg.aranr_id, askepNo: row.aranas_id},
            query: {form: form}
          })
          .catch(()=>{})
        }else{
          this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, askepNo: row.aranas_id}})
          .catch(()=>{})
        }
      },

      hapusAskep(row){
        this.$swal({
          icon: 'warning',
          title: 'Hapus Asuhan Keperawatan?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'

        }).then(result => {
          if(result.value){
            this.loadingOverlay = true
            let data = {
              id : row.aranas_id
            }
            data.type = 'hapus-askep'
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
              this.loadingOverlay = false
              this.$swal({
                title: "Tindakan Berhasil DiHapus",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.apiGet()
            })
          }
        })
      },

    },
    mounted() {
      this.apiGet()
      this.getPatientInfo()
    },
    watch:{
      $route(){
        this.apiGet()
        this.getPatientInfo()
      }
    }

  }
</script>
