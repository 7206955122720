<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Waktu & Nama Perawat</label>
          <v-select @input="changeRiwayat($event)" placeholder="Pilih Data Kajian" v-model="isParent.rowEdit.aranas_id" :options="mRiwayatAskep" label="text" :reduce="v=>v.value"></v-select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Tanggal</label>
          <h6 class="font-weight-semibold">{{rowData.aranas_date | moment("DD MMMM YYYY")}} {{rowData.aranas_jam}}</h6>
        </div>
      </div>
    </div>

    <div class="card border shadow-0">
      <div class="card-header bg-info">
        <h5 class="card-title font-weight-semibold">Standar Diagnosis Keperawatan Indonesia (SDKI)</h5>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs nav-tabs-bottom">
          <template v-for="(v,k) in (diagnosa||[])">
            <li class="nav-item" v-if="v.msd_name" :key="k">
                <a href="javascript:;" @click="changeTabSDKI(k+1)" data-toggle="tab" 
                :data-target="'#diagTab'+(k+1)" 
                :class="activeTabSDKI == (k+1) ? 'nav-link active' : 'nav-link'">
                {{v.msd_code + " - " +v.msd_name||"Diagnosa baru"}}                
                </a>
            </li>
          </template>
        </ul>
        <div class="tab-content card-body p-0">
            <div v-for="(v,k) in (diagnosa||[])" :key="k+'a'" 
            :class="activeTabSDKI == (k+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#diagTab'+(k+1)">
            <div class="row">
                <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-bordered">
                    <tbody>
                        <tr>
                        <td>
                            <div class="result_tab">
                            <h4>Diagnosa</h4>
                            <p>{{v.msd_code ? v.msd_code + ' - ' + v.msd_name : '-'}}</p>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div class="form-row mt-2">
                <div class="col-md-6">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Penyebab</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="form-row">
                        <div class="col-md-12">
                        <template v-for="(v,k) in (diagnosa[k].aranasd_penyebab||[])">
                            <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                            <i class="icon-checkmark-circle text-success"></i>
                            <span class="ml-1">{{v}}</span>
                            </div>
                        </template>
                        <p v-if="!(diagnosa[k].aranasd_penyebab||[]).length">Tidak Ada</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                
                <div class="col-md-6">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Faktor Resiko</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="form-row">
                        <div class="col-md-6">
                        <template v-for="(v,k) in (diagnosa[k].aranasd_faktor_resiko||[])">
                            <div :key="k+'faktor-res'" class="d-flex align-items-center  mb-2">
                            <i class="icon-checkmark-circle text-success"></i>
                            <span class="ml-1">{{v}}</span>
                            </div>
                        </template>
                        <p v-if="!(diagnosa[k].aranasd_faktor_resiko||[]).length">Tidak Ada</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-md-6">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="form-row">
                        <div class="col-md-6">
                        <h6 class="font-weight-semibold">Subjective</h6>
                        <template v-for="(v,k) in (diagnosa[k].aranasd_gejala_mayor_subjektif||[])">
                            <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                            <i class="icon-checkmark-circle text-success"></i>
                            <span class="ml-1">{{v}}</span>
                            </div>
                        </template>
                        <p v-if="!(diagnosa[k].aranasd_gejala_mayor_subjektif||[]).length">Tidak Ada</p>
                        </div>
                        <div class="col-md-6">
                        <h6 class="font-weight-semibold">Objective</h6>
                        <template v-for="(v,k) in (diagnosa[k].aranasd_gejala_mayor_objektif||[])">
                            <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                            <i class="icon-checkmark-circle text-success"></i>
                            <span class="ml-1">{{v}}</span>
                            </div>
                        </template>
                        <p v-if="!(diagnosa[k].aranasd_gejala_mayor_objektif||[]).length">Tidak Ada</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-md-6">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="form-row">
                        <div class="col-md-6">
                        <h6 class="font-weight-semibold">Subjective</h6>
                        <template v-for="(v,k) in (diagnosa[k].aranasd_gejala_minor_subjektif||[])">
                            <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                            <i class="icon-checkmark-circle text-success"></i>
                            <span class="ml-1">{{v}}</span>
                            </div>
                        </template>
                        <p v-if="!(diagnosa[k].aranasd_gejala_minor_subjektif||[]).length">Tidak Ada</p>
                        </div>
                        <div class="col-md-6">
                        <h6 class="font-weight-semibold">Objective</h6>
                        <template v-for="(v,k) in (diagnosa[k].aranasd_gejala_minor_objektif||[])">
                            <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                            <i class="icon-checkmark-circle text-success"></i>
                            <span class="ml-1">{{v}}</span>
                            </div>
                        </template>
                        <p v-if="!(diagnosa[k].aranasd_gejala_minor_objektif||[]).length">Tidak Ada</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-md-12">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Standar Luaran Keperawatan Indonesia
                        (SLKI)
                    </h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="form-row">
                        <div class="col-md-12">
                        <ul class="nav nav-tabs nav-tabs-bottom">
                            <li class="nav-item" v-for="(v1,k1) in v.slki" :key="'Luaran'+k1">
                            <a href="javascript:;" @click="changeTabSLKI(k1+1,k)" data-toggle="tab" 
                            :data-target="'#luarTab'+(k1+1)" :class="v.aranasd_last_tab_slki == (k1+1) ? 'nav-link active' : 'nav-link'">
                            {{  v1.msl_code + " - " + v1.msl_name || "Luaran Baru"}}</a>
                            </li>
                        </ul>
                        <div class="tab-content card-body p-0">
                            <div v-for="(v1,k1) in v.slki" :key="k1+'lu'" :class="v.aranasd_last_tab_slki == (k1+1) ? 'tab-pane fade show active':'tab-pane fade'"  :id="'#luarTab'+(k1+1)">
                            <div class="row">
                                <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                        <td>
                                            <div class="result_tab">
                                            <h4> Luaran</h4>
                                            <p>{{v1.msl_code ? v1.msl_code + ' - ' + v1.msl_name : '-'}}</p>
                                            </div>
                                        </td>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Ekspektasi</h4>
                                            <p>{{v1.aranasl_ekspetasi||"-"}}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Kriteria</h4>
                                            <p v-if="v1.aranasl_kriteria_waktu1 && v1.aranasl_kriteria_waktu2">{{v1.aranasl_kriteria_waktu1}}x{{v1.aranasl_kriteria_waktu2}} jam</p>
                                            <p v-else> - </p>
                                          </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                                <div class="col-md-12">
                                <table
                                    class="table table-bordered table-striped table-hover table-sm patient-table">
                                    <thead>
                                    <tr>
                                        <th>Kriteria</th>
                                        <th>Skor</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v11,k11) in (v1.aranasl_kriteria_hasil||[])" :key="k11+'kh'">
                                        <td>
                                        {{v11.name||"-"}}
                                        </td>
                                        <td>{{v11.hasil||"-"}}</td>
                                    </tr>
                                    <tr v-if="!(v1.aranasl_kriteria_hasil||[]).length">
                                      <td colspan="99" class="text-center">Tidak ada Kriteria</td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-md-12">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Standar Intervensi Keperawatan Indonesia
                        (SIKI)
                    </h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="form-row">
                        <div class="col-md-12">
                        <ul class="nav nav-tabs nav-tabs-bottom">
                                
                            <li class="nav-item" v-for="(v2,k2) in v.siki" :key="'Intervensi'+k2">
                            <a href="javascript:;" @click="changeTabSIKI(k2+1,k)" data-toggle="tab" 
                            :data-target="'#intvTab'+(k2+1)" :class="v.aranasd_last_tab_siki == (k2+1) ? 'nav-link active' : 'nav-link'">
                            {{ v2.msi_code + " - " +v2.msi_name || "Intervensi Baru"}}
                            </a>
                            </li>
                        </ul>
                        <div class="tab-content card-body p-0">
                            <div v-for="(v2,k2) in v.siki" :key="k2+'sik'" :class="v.aranasd_last_tab_siki == (k2+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#intvTab1'+(k2+1)">
                            <div class="row">
                                <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                        <td>
                                            <div class="result_tab">
                                            <h4> Intervensi</h4>
                                            <p>{{v2.msi_code ? v2.msi_code + ' - ' + v2.msi_name : '-'}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                                <div class="col-md-12">
                                <table
                                    class="table table-bordered table-striped table-hover table-sm patient-table">
                                    <thead>
                                        <tr>
                                        <th class="table-primary" colspan="99">Tindakan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <th colspan="2" class="table-light bg_head_panel">OBSERVASI</th>
                                        </tr>
                                        <template v-for="(vo,ko) in diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi']" >
                                            <tr :key="ko+'ko'" v-if="vo.value">
                                                <td colspan="2">{{vo.name}}</td>
                                            </tr>
                                        </template>
                                        <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi'])">
                                            <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                        <th colspan="2" class="table-light bg_head_panel">TERAPEUTIK</th>
                                        </tr>
                                        <template v-for="(vt,kt) in diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik']" >
                                            <tr :key="kt+'kt'" v-if="vt.value">
                                                <td colspan="2">{{vt.name}}</td>
                                            </tr>
                                        </template>
                                        <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik'])">
                                            <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                        <th colspan="2" class="table-light bg_head_panel">EDUKASI</th>
                                        </tr>
                                        <template v-for="(ve,ke) in diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi']" >
                                            <tr :key="ke+'ke'" v-if="ve.value">
                                                <td colspan="2">{{ve.name}}</td>
                                            </tr>
                                        </template>
                                        <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi'])">
                                            <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                        </tr>
                                        
                                    </tbody>
                                    <tbody>
                                        <tr>
                                        <th colspan="2" class="table-light bg_head_panel">KOLABORASI</th>
                                        </tr>
                                        <template v-for="(vk,kk) in diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi']" >
                                            <tr :key="kk+'kk'" v-if="vk.value">
                                                <td colspan="2">{{vk.name}}</td>
                                            </tr>
                                        </template>
                                        <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi'])">
                                            <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-md-12">
                  <div class="card border shadow-0 mt-3">
                    <div class="card-header bg-info">
                      <h5 class="card-title font-weight-semibold">Pelaksanaan</h5>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Pelaksanaan</th>
                            <th>Waktu</th>
                            <th>Perawat</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(vti,kti) in (v.aranasd_tindakan_keperawatan||[])" :key="kti+'kti'">
                            <td>{{vti.name||"-"}}</td>
                            <td>
                              <template v-if="vti.isChecked == 'Y'">
                                <span v-if="vti.dates">{{vti.dates|moment("DD MMMM YYYY")}} {{vti.time.HH}}:{{vti.time.mm}}</span>
                                <span v-else> - </span>
                              </template>
                              <span v-else> - </span>
                           </td>
                           <td>
                              <template v-if="vti.isChecked == 'Y'">
                                {{vti.ppa}}
                              </template>
                              <span v-else> - </span>
                           </td>
                            <td>
                              <span class="badge badge-success" v-if="vti.isChecked == 'Y'">Terlaksana</span>
                              <span class="badge badge-warning" v-else>Belum Terlaksana</span>
                            </td>
                          </tr>
                          <tr v-if="!(v.aranasd_tindakan_keperawatan||[]).length">
                            <td colspan="99" class="text-center">Tidak ada data</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card-body">
                      <div class="form-group">
                        <div class="result_tab">
                          <h4>Perawat yang Mengisi</h4>
                          <p>{{v.aranasd_nama_perawat||"-"}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card border mb-0 shadow-0">
                    <div class="card-header bg-info">
                      <h5 class="card-title font-weight-semibold">Evaluasi</h5>
                    </div>
                    <div class="card-header bg-white">
                      <h6 class="card-title font-weight-semibold">Respon</h6>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-sm">
                        <thead>
                          <tr>
                            <th rowspan="2" width="33%">Pelaksanaan</th>
                            <th colspan="2">Respon</th>
                          </tr>
                          <tr>
                            <th>S</th>
                            <th>O</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(vti,kti) in (v.aranasd_tindakan_keperawatan||[])" :key="kti+'ke'">
                            <td>{{vti.name||"-"}}</td>
                            <td>
                              {{vti.subjektif||"-"}}
                            </td>
                            <td>
                              {{vti.objektif||"-"}}
                            </td>
                          </tr>
                          <tr v-if="!(v.aranasd_tindakan_keperawatan||[]).length">
                            <td colspan="99" class="text-center">Tidak ada data</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card-header bg-white">
                      <h6 class="card-title font-weight-semibold">Hasil</h6>
                    </div>
                    <span class="ml-2 mb-2" v-if="v.aranasd_hasil_date && v.aranasd_hasil_time">Keluar Pada : {{v.aranasd_hasil_date | moment("DD MMMM YYYY")}} {{v.aranasd_hasil_time}}</span>
                    <div class="table-responsive">
                      <table class="table table-sm table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <div class="label_code label_code_sm m-0 mr-2">
                                  <h2>S</h2>
                                </div>
                                <span class="font-weight-semibold">Subjective</span>
                              </div>
                            </td>
                            <td>
                              <pre class="pre-input mb-1">{{v.aranasd_subjective||"-"}}</pre>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <div class="label_code label_code_sm m-0 mr-2">
                                  <h2>O</h2>
                                </div>
                                <span class="font-weight-semibold">Objective</span>
                              </div>
                            </td>
                            <td>
                              <pre class="pre-input mb-1">{{v.aranasd_objective||"-"}}</pre>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <div class="label_code label_code_sm m-0 mr-2">
                                  <h2>A</h2>
                                </div>
                                <span class="font-weight-semibold">Assessment</span>
                              </div>
                            </td>
                            <td>
                              <span>{{v.aranasd_assesment||"-"}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <div class="label_code label_code_sm m-0 mr-2">
                                  <h2>P</h2>
                                </div>
                              <span>Planning</span>
                              </div>
                            </td>
                            <td>
                              <div class="form-group">
                                <div>{{getConfigDynamic(Config.mr.mSDKIPlanning,v.aranasd_planing)||"-"}}</div>
                              </div>
                              <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Standar Intervensi Keperawatan Indonesia
                                    (SIKI)
                                  </h6>
                                </div>
                                <div class="card-body p-3">
                                  <div class="form-row">
                                    <div class="col-md-12">
                                      <ul class="nav nav-tabs nav-tabs-bottom mb-0">
                                          <li class="nav-item" v-for="(v1,k1) in v.siki" :key="'Luaran Next'+k1">
                                          <a href="javascript:;" @click="changeTabSIKINext(k1+1,k)"
                                            :class="v.last_siki_next == (k1+1) ? 'nav-link active' : 'nav-link'">
                                            {{ v1.text || "Luaran Baru"}}</a>
                                          </li>
                                      </ul>
                                      <div class="tab-content card-body p-0">
                                      	<div class="tab-content">
                                          <div v-for="(v2,k2) in v.siki" :key="k2+'in'"
                                          :class="v.last_siki_next == (k2+1) ? 'tab-pane fade show active':'tab-pane fade d-none'">
                                            <div class="row">
                                              <div class="col-md-12">
                                                <div class="table-responsive">
                                                  <table class="table table-bordered">
                                                    <tbody>
                                                      <tr>
                                                        <td>
                                                          <div class="result_tab">
                                                            <h4> Intervensi</h4>
                                                            <p>{{v2.msi_code ? v2.msi_code + ' - ' + v2.msi_name : '-'}}</p>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                                                  <thead>
                                                    <tr>
                                                      <th>Tindakan</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr>
                                                      <th colspan="2" class="table-light bg_head_panel">OBSERVASI</th>
                                                      </tr>
                                                      <template v-for="(vo,ko) in diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi_next']" >
                                                          <tr :key="ko+'ko'" v-if="vo.value">
                                                              <td colspan="2">{{vo.name}}</td>
                                                          </tr>
                                                      </template>
                                                      <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aranasi_tindakan_observasi_next'])">
                                                          <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                      </tr>
                                                  </tbody>
                                                  <tbody>
                                                      <tr>
                                                      <th colspan="2" class="table-light bg_head_panel">TERAPEUTIK</th>
                                                      </tr>
                                                      <template v-for="(vt,kt) in diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik_next']" >
                                                          <tr :key="kt+'kt'" v-if="vt.value">
                                                              <td colspan="2">{{vt.name}}</td>
                                                          </tr>
                                                      </template>
                                                      <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aranasi_tindakan_terapeutik_next'])">
                                                          <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                      </tr>
                                                  </tbody>
                                                  <tbody>
                                                      <tr>
                                                      <th colspan="2" class="table-light bg_head_panel">EDUKASI</th>
                                                      </tr>
                                                      <template v-for="(ve,ke) in diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi_next']" >
                                                          <tr :key="ke+'ke'" v-if="ve.value">
                                                              <td colspan="2">{{ve.name}}</td>
                                                          </tr>
                                                      </template>
                                                      <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aranasi_tindakan_edukasi_next'])">
                                                          <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                      </tr>
                                                      
                                                  </tbody>
                                                  <tbody>
                                                      <tr>
                                                      <th colspan="2" class="table-light bg_head_panel">KOLABORASI</th>
                                                      </tr>
                                                      <template v-for="(vk,kk) in diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi_next']" >
                                                          <tr :key="kk+'kk'" v-if="vk.value">
                                                              <td colspan="2">{{vk.name}}</td>
                                                          </tr>
                                                      </template>
                                                      <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aranasi_tindakan_kolaborasi_next'])">
                                                          <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                      </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>        
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  data() {
    return {
      rowData: {},
      isActiveTab : null,
      openFollowUpTindakan : false,
      rowFollowUp: {} ,
      diagnosa: [],
      mSDKI: [],
      mRiwayatAskep: [],
      
      activeTabSDKI: 1,
      activeTabSLKI: 1,
      activeTabSIKI: 1
    }
  },
  methods: {
    back() {
      this.$router.back()
    },    
    changeTabSIKINext(k1,k){
			this.diagnosa[k].last_siki_next = k1
    },  
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.isParent.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.isParent.rowEdit.aranas_id+'?isDetail=1'
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.isParent.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.isParent.loadingOverlay = false
      })

      // master
      if(this.$route.params.askepNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },
    changeTabSDKI(k){
      this.activeTabSDKI = k
    },
    changeTabSLKI(k1,k){
      this.diagnosa[k].aranasd_last_tab_slki = k1
    },
    changeTabSIKI(k2,k){
      this.diagnosa[k].aranasd_last_tab_siki = k2
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value != null){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    countTindakan(data){
        let total = 0
        for(let i = 0; i < (data||[]).length; i++){
            if(data[i]['value']){
                total += 1
            }
        }
        return total
    },
    changeRiwayat(e){
      this.apiGetForm()
    }
  },
  mounted() {
    this.apiGetForm()  
  },
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
}
</script>